import React from 'react';
import { graphql } from 'gatsby';
import { SanityB2BContactPage } from '../../../graphql-types';
import { H2 } from '@ph-bit/design.ui.typography.h2';
import { H4 } from '@ph-bit/design.ui.typography.h4';
import { P2 } from '@ph-bit/design.ui.typography.p2';

import {
  Pill,
  PillColorTheme,
  PillVariant
} from '@ph-bit/design.ui.buttons.pill';

import {
  BasicFields,
  ContactPageLayout,
  styles,
  useContactForm,
  YesNo
} from '../molecules/contact';

interface B2BContactPageTemplateProps {
  data: {
    sanityB2BContactPage: SanityB2BContactPage;
  };
}

export default function B2BContactPageTemplate({
  data: {
    sanityB2BContactPage: {
      client,
      copy = 'Our Parsley advisors are here to answer all your questions and help decide if Parsley Health is right for you. Book a free 15-minute consultation below.',
      calendlyFormUrl,
      partnerImage,
      heading = 'Have questions about Parsley Health?'
    }
  }
}: B2BContactPageTemplateProps) {
  const {
    onSubmit,
    register,
    control,
    errors,
    onDropdownChange,
    isSelectedStateDisabled
  } = useContactForm({
    defaultValues: {
      coveredByEmployer: YesNo.YES,
      employer: client.toUpperCase(),
      client: client
    },
    formFullUrl: calendlyFormUrl
  });

  return (
    <ContactPageLayout pageTitle="Contact Us | Parsley Health">
      {partnerImage?.asset && (
        <div className="mb-8">
          <img src={partnerImage.asset.url} alt={partnerImage.asset.altText} />
        </div>
      )}
      <H2>{heading}</H2>
      <div className="my-8">
        <P2 className="mb-6 px-4">{copy}</P2>
      </div>
      <H4>Enter your information</H4>
      <div className="my-6 w-full">
        <form className={styles.form} id="form" onSubmit={onSubmit}>
          <BasicFields
            control={control}
            errors={errors}
            isSelectedStateDisabled={isSelectedStateDisabled}
            onDropdownChange={onDropdownChange}
            register={register}
            validateStates={false}
          />
          <div className="mt-6 flex-center flex-col">
            <Pill
              className="flex-center [&>*]:text-ph-sage"
              variant={PillVariant.primary}
              colorTheme={PillColorTheme.sage}
              type="submit"
              form="form"
            >
              {isSelectedStateDisabled ? 'Notify me' : 'Select date & time'}
            </Pill>
          </div>
        </form>
      </div>
    </ContactPageLayout>
  );
}

export const query = graphql`
  query B2BContactPageTemplateQuery($id: String!) {
    sanityB2BContactPage(id: { eq: $id }) {
      client
      slug {
        current
      }
      partnerImage {
        ...SanityImageFragmentV2
      }
      heading
      copy
      calendlyFormUrl
    }
  }
`;
